import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  users
  headers = ['image', 'name', 'role', 'phone', 'email'];
  loading: boolean = true;
  filterForm: FormGroup = this.fb.group({
    role: [null],
    name: [null],
    email: [null]
  });
  constructor(private userService: UserService, private cdr: ChangeDetectorRef, private router: Router, private fb: FormBuilder,  private dialog: MatDialog) { }

  ngOnInit() {
    this.loading = false
    const { _id } = JSON.parse(localStorage.getItem('currentUser'))
    this.userService.getUsers(_id).subscribe(res => {
      this.users = res
      this.cdr.detectChanges();
      setTimeout(() => {
        this.loading = false;
      }, 500)
    }, error => {
      this.errorDialog('System fails to get list of users')
      this.loading = false
    })
  }

  // updateUser() {
    
  //   this.ngOnInit()
  // }

  onClick(id: string) {
    this.router.navigate(['/user', id]);
  }

  filter() {
    const { _id } = JSON.parse(localStorage.getItem('currentUser'))
    const { role, name, email } = this.filterForm.value;
    this.userService.viewUserFilter(role, name, email, _id).subscribe(res => {
      this.users = res
      // this.ngOnInit()
      // this.makeRows();
    }, error => {
      this.errorDialog('System fails to apply filters to list of users')
    });
  }

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

}
