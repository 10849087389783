import { Injectable } from '@angular/core';
import { RegisterService } from './register.service';
import { Student } from '../entity/student';
import { Observable } from 'rxjs';
import { Lecturer } from '../entity/lecturer';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterImplService extends RegisterService{

  constructor(private http: HttpClient) { 
    super()
  }
  registerStudent(student: Student): Observable<Student> {
    return this.http.post<Student>(`${environment.registerApi}/student`, student)
  }
  registerLecturer(lecturer: Lecturer): Observable<Lecturer> {
    return this.http.post<Lecturer>(`${environment.registerApi}/lecturer`, lecturer)
  }

}
