import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration-card',
  templateUrl: './registration-card.component.html',
  styleUrls: ['./registration-card.component.css']
})
export class RegistrationCardComponent implements OnInit {

  @Input() person
  @Output() personChange = new EventEmitter<any>(); 

  imageSrc
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.imageSrc = 'data:image/png;base64,' + this.person['roleIdImage']
  }

  approve() {
    environment.endpoint
    this.http.post<any>(environment.endpoint + `/change-status/${this.person.userId}/${true}`, "").subscribe(res => {
      this.personChange.emit(this.personChange)
    })
  }

  reject() {
    this.http.delete<any>(environment.endpoint+ `/user/${this.person.userId}`).subscribe(res => {
      this.personChange.emit(this.personChange)
    })
  }


}
