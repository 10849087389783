import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { XlsxService } from 'src/app/service/xlsx.service';
import { MatDialog } from '@angular/material';
import { VisualisationDialogComponent } from 'src/app/components/visualisation-dialog/visualisation-dialog.component';
import { MailService } from 'src/app/service/mail.service';
import { Mail } from 'src/app/entity/mail';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-view-score',
  templateUrl: './view-score.component.html',
  styleUrls: ['./view-score.component.css']
})
export class ViewScoreComponent implements OnInit {
  fb: FormBuilder = new FormBuilder();
  headers = [];
  filterForm: FormGroup = this.fb.group({
    courseId: [null],
    year: [null],
    semester: [null]
  });

  reportForm: FormGroup = this.fb.group({
    subject: [null],
    text: [null]
  })
  scoreList: any[] = [];
  loading: boolean = true;
  constructor(
    private xlsxService: XlsxService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private mailService: MailService
    ) { }

  ngOnInit() {
    const studentId = JSON.parse(localStorage.getItem('studentId'))
    this.xlsxService.getStudentScore(studentId).subscribe(data => {
      this.scoreList = data;
      this.cdr.detectChanges();
      for (let i = 0; i < this.scoreList.length; i++) {
        this.headers[i] = Object.keys(this.scoreList[i]).filter(item => (
          item !== 'totalScore'
          && item !== 'courseId'
          && item !== 'courseName'
          && item !== 'year'
          && item !== 'semester'
          && item !== 'studentId'
          && item !== 'imgBytes'
          && item !== 'min'
          && item !== 'max'
          && item !== 'mean'
          && item !== 'std'
          && item !== 'lecturerId'
          && item !== 'lecturerEmail'
          ));
        this.headers[i].push('totalScore');
      }
      setTimeout(() => {
        this.loading = false;
      }, 500)
     }, error => {
      this.errorDialog('System fails to get list of scores')
       this.loading = false
     });
  }

  filter() {
    const studentId = JSON.parse(localStorage.getItem('studentId'))
    const { courseId, year, semester } = this.filterForm.value;
    this.xlsxService.viewScoreFilter(courseId, year, semester, studentId).subscribe( res => {
      this.scoreList = res;
      this.cdr.detectChanges();
    }, error => {
      this.errorDialog('System fails to apply filters to list of scores')
    });
  }

  panelOpenState: boolean = false;

  togglePanel() {
      this.panelOpenState = !this.panelOpenState
      this.cdr.detectChanges();
  }  

  openDialog(studentScore): void {
    const dialogRef = this.dialog.open(VisualisationDialogComponent, {
      width: '750px',
      data: {
        imgBytes: studentScore['imgBytes'],
        role: 'student'
    }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  sendEmail(studentScore) {
    const { username } = JSON.parse(localStorage.currentUser)
    const { subject, text } = this.reportForm.value
    const mail: Mail = {
      from: username,
      recipients: [ studentScore.lecturerEmail ],
      subject,
      content: { text },
      sendDate: ''
    };
    this.mailService.sendMail(mail).subscribe(res => {
      // console.log(res)
    })
  }

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
}
