import { Injectable } from '@angular/core';
import { MailService } from './mail.service';
import { Mail } from '../entity/mail';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailImplService extends MailService{

  constructor(private http: HttpClient) { 
    super()
  }

  sendMail(mail: Mail): Observable<Mail> {
    return this.http.post<Mail>(`${environment.mailApi}`, mail)
  }

  getMailByFrom(email: string): Observable<Mail[]> {
    return this.http.get<Mail[]>(`${environment.mailApi}?from=${email}`)
  }
}
