import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export abstract class AuthenticationService {
    abstract signin(params: HttpParams);
    abstract signout();
    abstract getToken(): string;
    abstract getCurrentUser();
    abstract hasRole(role: string): boolean;



}
