import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MailService } from 'src/app/service/mail.service';
import { Mail } from 'src/app/entity/mail';

@Component({
  selector: 'app-view-mail',
  templateUrl: './view-mail.component.html',
  styleUrls: ['./view-mail.component.css']
})
export class ViewMailComponent implements OnInit {

  mailList: Mail[];
  constructor(
    private mailService: MailService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const { username } = JSON.parse(localStorage.currentUser);
    this.mailService.getMailByFrom(username).subscribe(res => {
      this.mailList = res
      this.cdr.detectChanges()
    })
  }

  editString(str) {
    if(str.length > 10) str = str.substring(0,10)+"...";
    return str
  }

}
