import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('fileInput') myInputVariable: ElementRef;
  profile
  selectedFile = null;
  imgBytes = '';
  loading: boolean = false;
  pf: FormGroup = this.fb.group({
    phoneNumber: [{value: '', disabled: true}, [Validators.required, Validators.minLength(10), Validators.maxLength(10),]],
    enableEmailNotification: [null , [Validators.required]],
  });
  validation_messages = {
    'phoneNumber': [
      { type: 'required', message: 'Phone number is <strong>required</strong>' },
      { type: 'minlength', message: 'Phone number is must be <strong>10 digits</strong> ' },
      { type: 'maxlength', message: 'Phone number is must be <strong>10 digits</strong>' },
    ]
  };
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.profile = JSON.parse(localStorage.getItem('currentUser'))
    // if (!this.profile) {
    //   this.errorDialog('System fails to get profile information')
    //   return
    // }
    const { phoneNumber, profileImage, enableEmailNotification } = this.profile
    this.pf.patchValue({
      phoneNumber,
      enableEmailNotification
    })
    this.pf.get('phoneNumber').disable()
    this.imgBytes = profileImage
    this.cdr.detectChanges()
  }

  edit() {
    this.pf.get('phoneNumber').enable()
  }

  ifChange() {
    const { phoneNumber, profileImage, enableEmailNotification } = this.profile
    // console.log(this.pf.value.phoneNumber)
    return !(
      phoneNumber !== this.pf.get('phoneNumber').value || 
      enableEmailNotification !== this.pf.get('enableEmailNotification').value ||
      profileImage !== this.imgBytes
      ) 
  }

  onFileSelected(event) {
    if (event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpeg') {
      this.errorDialog('Please upload either PNG OR JPEG')
      event.target.files = null
      this.myInputVariable.nativeElement.value = null;
      this.cdr.detectChanges()
      return
    } 
    this.selectedFile = event.target.files[0];
    event.target.files = null
    const fd = new FormData();
    fd.append('image', this.selectedFile, this.selectedFile.name);
    this.http.post(`${environment.imageApi}/convert-to-byte`, fd).subscribe(res => {
      this.imgBytes = res['imgBytes']
    })
  }

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onSubmit(event) {
    this.loading = true
    event.preventDefault();
    const { _id } = this.profile
    const profileImage = this.imgBytes
    const phoneNumber = this.pf.get('phoneNumber').value
    const enableEmailNotification = this.pf.get('enableEmailNotification').value
    this.userService.addProfileInformation({
      _id, phoneNumber, profileImage, enableEmailNotification
    }).subscribe(res => {
      localStorage.removeItem('currentUser')
      localStorage.setItem('currentUser', JSON.stringify(res))
      setTimeout(() => {
        this.loading = false;
      }, 500)
      this.cdr.detectChanges()
      this.ngOnInit()
    }, error => {
      this.errorDialog('System fails to save profile information')
      this.loading = false
    })
  }

}
