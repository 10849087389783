import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PersonService } from 'src/app/service/person.service';
import { RegistrationCardComponent } from 'src/app/components/registration-card/registration-card.component';

@Component({
  selector: 'app-manage-registration',
  templateUrl: './manage-registration.component.html',
  styleUrls: ['./manage-registration.component.css']
})
export class ManageRegistrationComponent implements OnInit {

  @ViewChild(RegistrationCardComponent) card: RegistrationCardComponent;

  persons
  constructor(private personService: PersonService, public cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.personService.getPersons().subscribe(res => {
      this.persons = res
    })
  }

  updatePersons() {
    this.ngOnInit();
  }

}
