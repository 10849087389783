export const environment = {
  production: true,
  hmr: false,
  endpoint: "https://score.camt.cmu.ac.th",
  xlsxApi: "https://score.camt.cmu.ac.th/xlsx",
  xlsxStudentApi: "https://score.camt.cmu.ac.th/xlsx/student",
  xlsxLecturerApi: "https://score.camt.cmu.ac.th/xlsx/lecturer",
  xlsxAdminApi: "https://score.camt.cmu.ac.th/xlsx/admin",
  oauthApi: "https://oauth.cmu.ac.th",
  itscApi:
    "https://misapi.cmu.ac.th/cmuitaccount/v1/api/cmuitaccount/basicinfo",
  authenticationApi: "https://score.camt.cmu.ac.th/auth",
  registerApi: "https://score.camt.cmu.ac.th/register",
  userApi: "https://score.camt.cmu.ac.th/user",
  personApi: "https://score.camt.cmu.ac.th/person",
  mailApi: "https://score.camt.cmu.ac.th/mail",
  imageApi: "https://score.camt.cmu.ac.th/image",
  baseUri: "https://scott.camt.cmu.ac.th",
  callbackUri: "https://scott.camt.cmu.ac.th/sign-in"
};
