import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { UploadXlsxFileComponent } from './pages/upload-xlsx-file/upload-xlsx-file.component';
import { ManageXlsxFileComponent } from './pages/manage-xlsx-file/manage-xlsx-file.component';
import { ViewFileComponent } from './pages/view-file/view-file.component';
import { EditXlsxFileComponent } from './pages/edit-xlsx-file/edit-xlsx-file.component';
import { LecturerGuard } from './guards/lecturer.guard';



const LecturerRoutes: Routes = [
    // { path: 'upload', component: UploadXlsxFileComponent},
    { path: 'upload', component: UploadXlsxFileComponent, canActivate: [LecturerGuard] },
    { path: 'edit/:id', component: EditXlsxFileComponent, canActivate: [LecturerGuard] },
    { path: 'lecturer/manage', component: ManageXlsxFileComponent, canActivate: [LecturerGuard] },
    { path: 'view-file/:id', component: ViewFileComponent, canActivate: [LecturerGuard] },

];
@NgModule({
    imports: [
        RouterModule.forRoot(LecturerRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class LecturerRoutingModule {

}
