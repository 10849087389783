import { Observable } from 'rxjs';
import { Student } from '../entity/student';
import { Lecturer } from '../entity/lecturer';

export abstract class RegisterService {
  abstract registerStudent(student: Student): Observable<Student>;
  abstract registerLecturer(lecturer: Lecturer): Observable<Lecturer>;
  
  
}
