import { Component, OnInit, Input, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  user
  loading: boolean = false;
  rf: FormGroup = this.fb.group({
    admin: [null],
    lecturer: [null],
    student: [null],
    enabled: [null]
  });

  validation_messages = {
    'role': { message: 'Role is <strong>required</strong>' },
  };

  imageSrc
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private dialog: MatDialog) { }

  ngOnInit() {
    // this.imageSrc = 'data:image/png;base64,' + this.person['roleIdImage']
    this.loading = true
    this.route.params.subscribe((params: Params) => {
      this.userService.getUser(params['id']).subscribe(res => {
        this.user = res
        if (this.user.authorities.map(authority => authority.name).includes("ROLE_STUDENT")) {
          this.rf.get('student').setValue(true)
        }
        if (this.user.authorities.map(authority => authority.name).includes("ROLE_LECTURER")) {
          this.rf.get('lecturer').setValue(true)
        }
        if (this.user.authorities.map(authority => authority.name).includes("ROLE_ADMIN")) {
          this.rf.get('admin').setValue(true)
        }
        this.rf.get('enabled').setValue(this.user.enabled)
        setTimeout(() => {
          this.loading = false;
        }, 500)
        this.cdr.detectChanges();
      }, error => {
        this.errorDialog('System fails to get user information')
        this.loading = false
      })

    });

  }

  ifChange() {
    const student = this.rf.get('student').value ? true : false
    const lecturer = this.rf.get('lecturer').value ? true : false
    const admin = this.rf.get('admin').value ? true : false
    const enabled = this.rf.get('enabled').value ? true : false
    // return !(
    //   this.user.authorities.map(authority => authority.name).includes('ROLE_LECTURER') !== this.pf.get('phoneNumber').value || 
    //   enableEmailNotification !== this.pf.get('enableEmailNotification').value ||
    //   profileImage !== this.imgBytes
    //   )
    if ((!student && !lecturer && !admin))
      return true

    return !(
      this.user.authorities.map(authority => authority.name).includes('ROLE_LECTURER') !== lecturer ||
      this.user.authorities.map(authority => authority.name).includes('ROLE_ADMIN') !== admin ||
      this.user.authorities.map(authority => authority.name).includes('ROLE_STUDENT') !== student ||
      this.user.enabled !== enabled
    )
  }

  // ngAfterViewChecked() {
  //   if (JSON.parse(localStorage.getItem('currentUser'))) {
  //     this.rf.get('enabled').setValue(this.user.enabled)
  //    this.cdr.detectChanges();      
  //  } 
  // }

  onSubmit(event) {
    this.loading = true
    event.preventDefault()
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Confirm',
        message: 'Do you confirm the changes?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const authorities = []
        const { _id } = this.user
        if (this.rf.get('admin').value) {
          authorities.push("ROLE_ADMIN")
        }
        if (this.rf.get('lecturer').value) {
          authorities.push("ROLE_LECTURER")
        }
        if (this.rf.get('student').value) {
          authorities.push("ROLE_STUDENT")
        }
        this.userService.setUserRoles({ _id, authorities }).subscribe(res => {
          this.userService.setUserStatus(_id, this.rf.get('enabled').value).subscribe(res => {
            setTimeout(() => {
              this.loading = false;
            }, 500)
            this.ngOnInit()
          })

        }, error => {
          this.errorDialog('System fails to save change information')
          this.loading = false
        })
      } else {
        this.loading = false
      }
    });
  } 

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

}
