import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Params, ParamMap, Router } from '@angular/router';
import { XlsxService } from 'src/app/service/xlsx.service';
import { MatDialog } from '@angular/material';
import { VisualisationDialogComponent } from 'src/app/components/visualisation-dialog/visualisation-dialog.component';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';


@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.css']
})
export class ViewFileComponent implements OnInit {
  dataSource = [];
  headers = [];
  outliers = [];
  imgHtml;
  stats = {
    min: 0,
    max: 0,
    mean: 0,
    std: 0
  };
  _id: string;
  disabled = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private xlsxService: XlsxService,
    private dialog: MatDialog
  ) { 
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.xlsxService.getXlsxFile(params['id'])
        // this.xlsxService.getFilebyId('5ce2cacc0ffee75280c441c0')
        .subscribe((data) => {
          this.stats['min'] = data['min']
          this.stats['max'] = data['max']
          this.stats['mean'] = data['mean']
          this.stats['std'] = data['std']
          this._id = data._id;
          this.dataSource = data['xlsxData'];
          this.imgHtml = data['imgHtml'];
          this.headers = Object.keys(this.dataSource[0]).filter(item => item !== 'totalScore');
          this.headers.push('totalScore');
          this.xlsxService.getOutlier(data._id).subscribe(
            data => {
              this.outliers = data;
              // console.log(this.outliers);
            }, error => {
              this.errorDialog('System fails to get the outlier result')
            })
        }, error => {
          this.errorDialog('System fails to get file data')
        });

    });
  }

  delete() {
    this.xlsxService.deleteXlsxFile(this._id).subscribe(res => {
      this.router.navigate(['/lecturer/manage']);
    }, error => {
      this.errorDialog('System fails to remove data')
    });
  }

  replace() {
    this.router.navigate(['/edit', this._id]);
  }

  openVisualisation(): void {
    const dialogRef = this.dialog.open(VisualisationDialogComponent, {
      width: '750px',
      height: '650px',
      data: {
        imgHtml: this.imgHtml,
        role: 'lecturer'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  indexMatch(rowArr: any[], columnArr: any[], rowIndex: number, columnIndex: number): boolean {
    if (rowArr == undefined || columnArr == undefined) return;
    let isMatched: boolean = false;
    for (let i = 0; i < rowArr.length; i++) {
      if (rowArr[i] == rowIndex && columnArr[i] == columnIndex) isMatched = true;
    }
    return isMatched;
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Confirm',
        message: 'Do you confirm the deletion of this data?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.delete()
      }
    });
  }
}
