import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, HostListener, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.css']
})
export class MyNavComponent implements OnInit, AfterViewChecked {

  profile
  navClass = {
    container: '',
    toolbar: ''
  }
  ngOnInit() {


  }

  ngAfterViewChecked() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      this.profile = JSON.parse(localStorage.getItem('currentUser'))
      this.cdr.detectChanges();
    } else {
      // console.log('not get on init!');
    }
  }


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private authenService: AuthenticationService, private cdr: ChangeDetectorRef, private router: Router) {

    this.profile = JSON.parse(localStorage.getItem('currentUser'))
    this.router.events.subscribe(event => {
      // filter `NavigationEnd` events
      if (event instanceof NavigationEnd) {
        // get current route without leading slash `/`
        const currentRoute = event.urlAfterRedirects.replace('/','').split("#")[0].split("?")[0];
        // set bgClass property with the value of the current route
        if (currentRoute === 'home') {
          this.navClass = {
            container: 'home',
            toolbar: 'toolbar'
          }
        } else {
          this.navClass = {
            container: '',
            toolbar: ''
          }
        }

        // console.log(this.navClass)
      }
    });

  }

  hasRole(role: string) {
    return this.authenService.hasRole(role)
  }

  signout() {
    this.authenService.signout()
  }

}
