import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationImplService extends AuthenticationService {

  constructor(
    private http: HttpClient,
  ) {
    super()
  }

  signin(params: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST'
      })
    };
    return this.http.post<any>(environment.authenticationApi, params, httpOptions).pipe(map(data => {
      if (data.token && data.user) {
        localStorage.setItem('token', data.token)
        localStorage.setItem('currentUser', JSON.stringify(data.user))
        if(data.user.authorities.map(authority => authority.name).includes("ROLE_STUDENT"))
          localStorage.setItem('studentId', JSON.stringify(data.studentId))
      }
      return data
    }))
  }

  signout() {
    localStorage.removeItem('token')
    localStorage.removeItem('currentUser')
    localStorage.removeItem('studentId')
    localStorage.removeItem('userId')
  }

  getToken(): string {
    const token = localStorage.getItem('token');
    return token ? token : '';
  }
  getCurrentUser() {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      return JSON.parse(currentUser);
    } else {
      return null;
    }
  }
  hasRole(role: string): boolean {
    const user: any = this.getCurrentUser();
    if (user) {
      const roleList: string[] = role.split(',');
      for (let j = 0; j < roleList.length; j++) {
        const authList = user.authorities;
        const userRole = 'ROLE_' + roleList[j].trim().toUpperCase();
        for (let i = 0; i < authList.length; i++) {
          if (authList[i].name === userRole) {
            return true;
          }
        }
      }
      return false;
    }
  }
}
