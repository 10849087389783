import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AdminManageXlsxFileComponent } from './pages/admin-manage-xlsx-file/admin-manage-xlsx-file.component';
import { ManageUserComponent } from './pages/manage-user/manage-user.component';
import { UserComponent } from './pages/user/user.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdminGuard } from './guards/admin.guard';



const AdminRoutes: Routes = [
    { path: 'admin/manage', component: AdminManageXlsxFileComponent, canActivate: [AdminGuard] },
  { path: 'admin/manage-user', component: ManageUserComponent, canActivate: [AdminGuard] },
  { path: 'user/:id', component: UserComponent, canActivate: [AdminGuard] },

];
@NgModule({
    imports: [
        RouterModule.forRoot(AdminRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AdminRoutingModule {

}
