import { Component, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { XlsxService } from 'src/app/service/xlsx.service';
import Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import { FormBuilder, FormGroup, Form, Validators } from '@angular/forms';
import { XlsxInfo } from 'src/app/entity/xlsxinfo';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-edit-xlsx-file',
  templateUrl: './edit-xlsx-file.component.html',
  styleUrls: ['./edit-xlsx-file.component.css']
})
export class EditXlsxFileComponent implements OnInit {

  _id: string;
  selectedFile = null;
  @ViewChild('inputFile') myInputVariable: ElementRef;
  sheets: String[][] = [];
  mode = '';
  // fb: FormBuilder = new FormBuilder();
  model: XlsxInfo;
  uf: FormGroup = this.fb.group({
    courseId: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]+$')]],
    courseName: [null, [Validators.required]],
    year: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    semester: [null, [Validators.required]],
    file: [null],
    sheetNo: [null, [Validators.required]],
    titleRow: [null, [Validators.required]],
    startRow: [null, [Validators.required]],
    endRow: [null, [Validators.required]],
    studentIdCol: [null, [Validators.required]],
    totalScoreCol: [null, [Validators.required]],
    cols: [null, [Validators.required]],
  });
  loading: boolean = true;
  validation_messages = {
    'courseId': [
      { type: 'required', message: 'Course Id is <strong>required</strong>' },
      { type: 'pattern', message: 'Course Id must be a <strong>number</strong>' },
      { type: 'minlength', message: 'Course Id is must be <strong>6 digits</strong> ' },
      { type: 'maxlength', message: 'Course Id is must be <strong>6 digits</strong>' },
    ],
    'courseName': [
      { type: 'required', message: 'Course name is <strong>required</strong>' },
    ],
    'year': [
      { type: 'required', message: 'Year is <strong>required</strong>' },
      { type: 'pattern', message: 'Year must be a <strong>number</strong>' },
      { type: 'minlength', message: 'Year is must be <strong>4 digits</strong> ' },
      { type: 'maxlength', message: 'Year Id is must be <strong>4 digits</strong>' },
    ],
    'semester': [
      { type: 'required', message: 'Semester is <strong>required</strong>' },
    ],
    'sheetNo': [
      { type: 'required', message: 'Sheet is <strong>required</strong>' },
    ],
    'titleRow': [
      { type: 'required', message: 'Title row is <strong>required</strong>' },
    ],
    'startRow': [
      { type: 'required', message: 'Start row is <strong>required</strong>' },
    ],
    'endRow': [
      { type: 'required', message: 'End row is <strong>required</strong>' },
    ],
    'studentIdCol': [
      { type: 'required', message: 'Student Id column is <strong>required</strong>' },
    ],
    'totalScoreCol': [
      { type: 'required', message: 'Total score column is <strong>required</strong>' },
    ],
    'cols': [
      { type: 'required', message: 'Score columns are <strong>required</strong>' },
    ],
  };
  settings: Handsontable.GridSettings = {
    width: '100%',
    height: 320,
    rowHeaders: true,
    colHeaders: true,
    licenseKey: 'non-commercial-and-evaluation',
    allowEmpty: true,
    selectionMode: 'multiple',
    manualColumnResize: true,
    manualRowResize: true,
  };
  private hotRegisterer = new HotTableRegisterer();
  id = 'hotInstanceId';
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private xlsxService: XlsxService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }


  sheetData = [];

  // data: any[] = Handsontable.helper.createSpreadsheetData(30, 30);

  toColumnName(num) {
    let ret = '';
    for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode((num % b) / a + 65) + ret;
    }
    return ret;
  }


  ngOnInit() {
    Handsontable.hooks.add('afterSelection', (row, column, row2, column2) => {
      const hot = this.hotRegisterer.getInstance(this.id);
      const selected = hot.getSelectedRange();
      if (this.mode === 'titleRow') {
        this.getTitleRow(row);
      } else if (this.mode === 'row') {
        this.getRows(row, row2);
      } else if (this.mode === 'studentId') {
        this.getStudentIdCol(selected[0].from.col);
      } else if (this.mode === 'totalScore') {
        this.getTotalScoreCol(selected[0].from.col);
      } else if (this.mode === 'cols') {
        this.getCols(selected);
      }

      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    Handsontable.hooks.add('beforeChange', (changes) => changes[0] = null);
    this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        this.xlsxService.getXlsxFile(params['id'])
          .subscribe((data) => {
            this._id = params['id'];
            this.model = data;
            this.uf.patchValue({
              courseId: data['courseId'],
              courseName: data['courseName'],
              year: data['year'],
              semester: data['semester'],
              sheetNo: data['sheetNo'],
              titleRow: data['titleRow'] + 1,
              startRow: data['startRow'] + 1,
              endRow: data['endRow'] + 1,
              studentIdCol: data['studentIdCol'],
              totalScoreCol: data['totalScoreCol'],
              cols: data['cols'],
            });
            this.sheetData = data['rawXlsxData'] ? data['rawXlsxData'] : []
            setTimeout(() => {
              this.loading = false;
            }, 500)
          });
      }
    });
  }

  getTitleRow(titleRow) {
    this.uf.patchValue({
      titleRow: titleRow + 1
    })
  }

  getRows(startRow, endRow) {
    if (endRow < startRow) {
      const temp = startRow;
      startRow = endRow;
      endRow = temp;
    }
    this.uf.patchValue({
      startRow: startRow + 1,
      endRow: endRow + 1
    });
  }

  getStudentIdCol(studentIdCol) {
    this.uf.patchValue({
      studentIdCol: this.toColumnName(studentIdCol + 1),
    });
  }

  getTotalScoreCol(totalScoreCol) {
    this.uf.patchValue({
      totalScoreCol: this.toColumnName(totalScoreCol + 1),
    });
  }

  getCols(cols) {
    const tempCols = this.uf.value.cols ? this.uf.value.cols : [];
    for (let i = 0; i < cols.length; i++) {
      const to = cols[i].to.col + 1;
      const from = cols[i].from.col + 1;
      if (from === to) {
        if (!tempCols.includes(this.toColumnName(from))) {
          tempCols.push(this.toColumnName(from));
        }
      } else {
        for (let j = from; j <= to; j++) {
          if (!tempCols.includes(this.toColumnName(j))) {
            tempCols.push(this.toColumnName(j));
          }
          if (!tempCols.includes(this.toColumnName(j))) {
            tempCols.push(this.toColumnName(j));
          }
        }
      }
    }

    this.uf.patchValue({
      cols: tempCols
    });
  }
  onTitleRowSelect() {
    this.mode = 'row';
  }

  onTitleRowChange() {
    this.mode = 'titleRow';
  }


  onRowSelect() {
    this.mode = 'studentId';
  }

  onRowChange() {
    this.mode = 'row';
  }

  onStudentIDSelect() {
    this.mode = 'totalScore';
  }

  onStudentIDChange() {
    this.mode = 'studentId';
    this.uf.patchValue({
      studentIdCol: ''
    });
  }

  onTotalScoreSelect() {
    this.mode = 'cols';
  }

  onTotalScoreChange() {
    this.mode = 'totalScore';
    this.uf.patchValue({
      totalScoreCol: ''
    });
  }

  onColsSelect() {
    this.mode = '';
  }

  onColsChange() {
    this.mode = 'cols';
    this.uf.patchValue({
      cols: []
    });
  }

  onFileSelected(event) {
    if (event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.errorDialog('Please upload xlsx file')
      event.target.files = null
      this.myInputVariable.nativeElement.value = null;
      this.cdr.detectChanges()
      return
    }
    this.selectedFile = event.target.files[0];
    event.target.files = null
    this.uf.patchValue({
        sheetNo: null
    })
    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    this.xlsxService.getXlsxSheets(fd).subscribe(sheets => {
      this.sheets = sheets;
      this.cdr.detectChanges();
    });
  }

  onUpload() {
    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('sheetNo', this.uf.value.sheetNo);
    this.xlsxService.getXlsxSheetData(fd).subscribe(data => {
      this.sheetData = data;
      this.cdr.detectChanges();
    });
  }

  onSubmit(event) {
    this.loading = true
    event.preventDefault();
    const { _id } = this;
    const { courseId, courseName, year, semester, sheetNo, studentIdCol, totalScoreCol, titleRow, startRow, endRow } = this.uf.value;
    let tempCols = this.uf.value.cols
    if (typeof tempCols === 'string')
      tempCols = JSON.parse("[" + tempCols.split(",").map(col => '\"' + col + '\"') + "]");

    let xlsxInfo = {
      courseId,
      courseName,
      year,
      semester,
      sheetNo,
      titleRow,
      startRow,
      endRow,
      studentIdCol,
      totalScoreCol,
      cols: tempCols
    };

    if (_id) {
      xlsxInfo['_id'] = _id;
      Object.keys(xlsxInfo).map((key) => {
        if (xlsxInfo[key] !== null) {
          this.model[key] = xlsxInfo[key];
        }
      });
      xlsxInfo = this.model;
    }

    xlsxInfo['email'] = JSON.parse(localStorage.getItem('currentUser')).username
    const fd = new FormData();
    fd.append('file', this.selectedFile);
    fd.append('json', JSON.stringify(xlsxInfo));
    this.xlsxService.updateXlsxInfo(fd).subscribe(res => {
      this.router.navigate(['/view-file', res['_id']]);
      this._snackBar.open("Upload xlsx file successfull", "Dismiss", {
        duration: 5000,
      });
      this.loading = false;
    }, error => {
      this.errorDialog(error.error.message)
      this.loading = false
      // console.log('System fails to save data')
    });
  }

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
}
