import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { XlsxImplService } from 'src/app/service/xlsx-impl.service';
import { XlsxService } from 'src/app/service/xlsx.service';
import { Http } from '@angular/http';

@Component({
  selector: 'app-visualisation-dialog',
  templateUrl: './visualisation-dialog.component.html',
  styleUrls: ['./visualisation-dialog.component.css']
})
export class VisualisationDialogComponent implements OnInit {

  imgHtml;
  imgBytes;
  role;

  htmlTemplate
  constructor(
    public dialogRef: MatDialogRef<VisualisationDialogComponent>,
    public xlsxService: XlsxService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data.role === 'lecturer') {
        this.imgHtml = data.imgHtml;
      } else {
        this.imgBytes = data.imgBytes
      }
      this.role = data.role
    }

   

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  ngOnInit() {
   

  }

}
