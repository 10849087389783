import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router, private dialog: MatDialog) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token')
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    return next.handle(request)
    // .pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     if (error.status === 401 || error.status === 403) {
    //       this.errorDialog("There seems to be a problem, please sign in again.")
          
    //     }
    //     return throwError(error);
    // }))
  }
  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.router.navigate(['/sign-in']);
    });
  }
}
