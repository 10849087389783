import { RouterModule, Routes } from "@angular/router";

import { AdminGuard } from "./guards/admin.guard";
import { AdminManageXlsxFileComponent } from "./pages/admin-manage-xlsx-file/admin-manage-xlsx-file.component";
import { AllRoleGuard } from "./guards/all-role.guard";
import { HomeComponent } from "./pages/home/home.component";
import { LecturerGuard } from "./guards/lecturer.guard";
import { ManageRegistrationComponent } from "./pages/manage-registration/manage-registration.component";
import { ManageUserComponent } from "./pages/manage-user/manage-user.component";
import { NgModule } from "@angular/core";
import { ProfileComponent } from "./pages/profile/profile.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { StudentGuard } from "./guards/student.guard";
import { UserComponent } from "./pages/user/user.component";
import { ViewScoreComponent } from "./pages/view-score/view-score.component";
import { environment } from "src/environments/environment";

const routes: Routes = [
  // { path: 'sign-in', component: SignInComponent },
  { path: "profile", component: ProfileComponent, canActivate: [AllRoleGuard] },
  { path: "home", component: HomeComponent },
  { path: "", component: HomeComponent },
  {
    path: "sign-in",
    canActivate: [RedirectComponent],
    component: RedirectComponent,
    data: {
      externalUrl: `https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=${"gQJfMeuvJCUDnZaxEvw8Qu2cKK3cuxx8BqTmBpDf"}&redirect_uri=${
        environment.callbackUri
      }&scope=cmuitaccount.basicinfo&state=xyz`
      // externalUrl: `https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=${'gQJfMeuvJCUDnZaxEvw8Qu2cKK3cuxx8BqTmBpDf'}&redirect_uri=${'http://scott.dto.technology'}&scope=cmuitaccount.basicinfo&state=xyz`
    }
  }
  // {
  //   path: '',
  //   canActivate: [RedirectComponent],
  //   component: RedirectComponent,
  //   data: {
  //     externalUrl: `https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=${'gQJfMeuvJCUDnZaxEvw8Qu2cKK3cuxx8BqTmBpDf'}&redirect_uri=${environment.baseUri}&scope=cmuitaccount.basicinfo&state=xyz`
  //     // externalUrl: `https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=${'gQJfMeuvJCUDnZaxEvw8Qu2cKK3cuxx8BqTmBpDf'}&redirect_uri=${'http://scott.dto.technology'}&scope=cmuitaccount.basicinfo&state=xyz`
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
