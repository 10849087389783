import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ViewScoreComponent } from './pages/view-score/view-score.component';
import { StudentGuard } from './guards/student.guard';
import { ViewMailComponent } from './pages/view-mail/view-mail.component';


const StudentRoutes: Routes = [
    { path: 'view-score', component: ViewScoreComponent, canActivate: [StudentGuard] },
    { path: 'view-mail', component: ViewMailComponent },
    // { path: 'view-score', component: ViewScoreComponent },
];
@NgModule({
    imports: [
        RouterModule.forRoot(StudentRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class StudentRoutingModule {

}
