import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Profile } from '../entity/profile';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Role } from '../entity/role';

@Injectable({
  providedIn: 'root'
})
export class UserImplService extends UserService {
  constructor(private http: HttpClient) {
    super()
  }

  addProfileInformation(profile: Profile): Observable<Profile> {
    return this.http.post<Profile>(`${environment.userApi}`, profile)
  }

  getUsers(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.userApi}/${id}/all`)
  }
  
  getUser(id: string): Observable<any> {
    return this.http.get<any[]>(`${environment.userApi}/${id}`)
  }

  setUserRoles(role: Role): Observable<any[]> {
    return this.http.post<any[]>(`${environment.userApi}/role`,role)
  }

  setUserStatus(id: String, status: boolean): Observable<any[]> {
    return this.http.post<any[]>(`${environment.userApi}/status?id=${id}&status=${status}`, status)
  }
  viewUserFilter(role: string, name: string, email: string, id: string): Observable<any[]> {
    const tempRole = role ? role : '';
    const tempName = name ? name : '';
    const tempEmail = email ? email : '';
    return this.http.get<any[]>(`${environment.userApi}/filter/${id}?role=${tempRole}&name=${tempName}&email=${tempEmail}`);
  }

}
