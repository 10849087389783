import { Profile } from "../entity/profile";
import { Observable } from "rxjs";

export abstract class UserService {
  abstract addProfileInformation(profile: Profile): Observable<Profile>;
  abstract getUsers(id: string): Observable<any[]>
  abstract getUser(id: string): Observable<any>
  abstract setUserRoles(authorities): Observable<any[]>
  abstract setUserStatus(id: String, status: boolean): Observable<any[]>
  abstract viewUserFilter(role: string, name: string, email: string, id: string): Observable<any[]>;
  
}

