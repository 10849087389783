import { OnInit, ViewChild, Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { XlsxInfo } from 'src/app/entity/xlsxinfo';
import { FormBuilder, FormGroup } from '@angular/forms';
import { XlsxService } from 'src/app/service/xlsx.service';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from 'src/app/components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-manage-xlsx-file',
  templateUrl: './manage-xlsx-file.component.html',
  styleUrls: ['./manage-xlsx-file.component.css']
})

export class ManageXlsxFileComponent implements OnInit {
  dataSource = [];
  columns: string[] = [
    'courseId', 'filename', 'size', 'uploadDate', 'year', 'buttons'
  ];
  data: XlsxInfo[];
  fb: FormBuilder = new FormBuilder();
  loading: boolean = true;
  filterForm: FormGroup = this.fb.group({
    courseId: [null],
    year: [null],
    semester: [null]
  });

  constructor(
    private xlsxService: XlsxService, private router: Router, private dialog: MatDialog
  ) {
  }

  onClick(id: string) {
    this.router.navigate(['/view-file', id]);
  }

  makeRows() {
    this.dataSource = this.data;
  }

  ngOnInit() {
    this.loading = true;
    const email = JSON.parse(localStorage.getItem('currentUser')).username
    this.xlsxService.getXlsxInfoByLecturerEmail(email).subscribe(res => {
      this.data = res;
      this.makeRows();
      setTimeout(() => {
        this.loading = false;
      }, 500)
    }, error => {
      this.errorDialog('System fails to get list of files')
      this.loading = false
    });
  }

  filter() {
    const email = JSON.parse(localStorage.getItem('currentUser')).username
    const { courseId, year } = this.filterForm.value;
    const semester = this.filterForm.get("semester").value
    console.log(semester)
    this.xlsxService.viewFileFilterForLecturer(courseId, year, semester, email).subscribe(res => {
      this.data = res;
      this.makeRows();
    }, error => {
      this.errorDialog('System fails to apply filters to list of files')
    });
  }

  delete(id: string) {
    // event.stopPropagation();
    this.xlsxService.deleteXlsxFile(id).subscribe(res => {
      this.filter()
    }, error => {
      this.errorDialog('System fails to remove data')
    });
  }

  replace(event, id: string) {
    event.stopPropagation();
    this.router.navigate(['/edit', id]);
  }

  errorDialog(message): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Error',
        message
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  openDeleteDialog(event, id: string): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      data: {
        type: 'Confirm',
        message: 'Do you confirm the deletion of this data?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.delete(id)
      }
    });
  }

}
