import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Component, Injectable, OnInit } from "@angular/core";

import { AuthenticationService } from "src/app/service/authentication.service";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.css"]
})
export class RedirectComponent implements CanActivate, OnInit {
  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log("hello world");
    if (state.url === "/sign-in") {
      console.log(state);
      window.location.href = (next.data as any).externalUrl;
    } else if (state.root.queryParams.code) {
      console.log(state);
      console.log("base uri = " + environment.baseUri);
      const { code } = state.root.queryParams;
      const params = new HttpParams({
        fromObject: {
          code,
          redirect_uri: environment.callbackUri,
          // redirect_uri: 'http://scott.dto.technology',
          client_id: "gQJfMeuvJCUDnZaxEvw8Qu2cKK3cuxx8BqTmBpDf",
          client_secret: "CHzXr9K5Yg21GXXYYFGansameVb7f06s2qGf4fXK",          
          grant_type: "authorization_code"
        }
      });

      this.auth.signin(params).subscribe(
        res => {
          console.log(res);
          if (JSON.parse(localStorage.getItem("currentUser"))["phoneNumber"]) {
            if (
              res.user.authorities
                .map(authority => authority.name)
                .includes("ROLE_ADMIN")
            ) {
              this.router.navigate(["/admin/manage"]);
            } else if (
              res.user.authorities
                .map(authority => authority.name)
                .includes("ROLE_LECTURER")
            ) {
              this.router.navigate(["lecturer/manage"]);
            } else if (
              res.user.authorities
                .map(authority => authority.name)
                .includes("ROLE_STUDENT")
            ) {
              this.router.navigate(["/view-score"]);
            }
          } else {
            this.router.navigate(["/profile"]);
          }
        },
        error => {
          // this.router.navigate(['/sign-in'])
        }
      );
    } else {
      console.log("else selected");

      this.router.navigate(["/sign-in"]);
    }
    console.log("end");

    return true;
  }
}
