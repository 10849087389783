import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HotTableModule } from '@handsontable/angular';
import { AppRoutingModule } from './app-routing.module';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyNavComponent } from './my-nav/my-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatFormFieldModule, MatInputModule
  , MatTableModule,
  MatSelectModule,
  MatOptionModule,
  MatRow,
  MatCell,
  MatExpansionModule,
  MatDialogModule,
  MatCardModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSnackBarModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { UploadXlsxFileComponent } from './pages/upload-xlsx-file/upload-xlsx-file.component';
import { ManageXlsxFileComponent } from './pages/manage-xlsx-file/manage-xlsx-file.component';
import { ViewFileComponent } from './pages/view-file/view-file.component';
import { XlsxService } from './service/xlsx.service';
import { XlsxImplService } from './service/xlsx-impl.service';
import { ViewScoreComponent } from './pages/view-score/view-score.component';
import { ImageSanitizerPipe } from './pipes/image-sanitizer.pipe';
import { VisualisationDialogComponent } from './components/visualisation-dialog/visualisation-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AdminManageXlsxFileComponent } from './pages/admin-manage-xlsx-file/admin-manage-xlsx-file.component';
import { EditXlsxFileComponent } from './pages/edit-xlsx-file/edit-xlsx-file.component';
import { HtmlSanitizerPipe } from './pipes/html-sanitizer.pipe';
import { ManageRegistrationComponent } from './pages/manage-registration/manage-registration.component';
import { StudentRoutingModule } from './student-routing.module';
import { AuthenticationService } from './service/authentication.service';
import { AuthenticationImplService } from './service/authentication-impl.service';
import { JwtInterceptor } from './service/jwt-interceptor.service';
import { LecturerRoutingModule } from './lecturer-routing.module';
import { RegisterService } from './service/register.service';
import { RegisterImplService } from './service/register-impl.service';
import { PersonService } from './service/person.service';
import { PersonImplService } from './service/person-impl.service';
import { RegistrationCardComponent } from './components/registration-card/registration-card.component';
import { MailService } from './service/mail.service';
import { MailImplService } from './service/mail-impl.service';
import { ViewMailComponent } from './pages/view-mail/view-mail.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UserService } from './service/user.service';
import { UserImplService } from './service/user-impl.service';
import { ManageUserComponent } from './pages/manage-user/manage-user.component';
import { UserComponent } from './pages/user/user.component';
import { HintDialogComponent } from './components/hint-dialog/hint-dialog.component';
import { AdminRoutingModule } from './admin-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    UploadXlsxFileComponent,
    ViewFileComponent,
    ManageXlsxFileComponent,
    ViewScoreComponent,
    ImageSanitizerPipe,
    VisualisationDialogComponent,
    AdminManageXlsxFileComponent,
    EditXlsxFileComponent,
    HtmlSanitizerPipe,
    ManageRegistrationComponent,
    RegistrationCardComponent,
    ViewMailComponent,
    RedirectComponent,
    MessageDialogComponent,
    ProfileComponent,
    ManageUserComponent,
    UserComponent,
    HintDialogComponent,
    HomeComponent
  ],
  entryComponents: [VisualisationDialogComponent, MessageDialogComponent, HintDialogComponent],
  imports: [
    BrowserModule,
    StudentRoutingModule,
    LecturerRoutingModule,
    AdminRoutingModule,
    AppRoutingModule,
    HotTableModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFileUploadModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatExpansionModule,
    MatDialogModule,
    MatCheckboxModule,
    MatCardModule,
    HttpModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    AngularSvgIconModule
  ],
  providers: [
    { provide: XlsxService, useClass: XlsxImplService },
    { provide: AuthenticationService, useClass: AuthenticationImplService },
    { provide: RegisterService, useClass: RegisterImplService },
    { provide: PersonService, useClass: PersonImplService },
    { provide: UserService, useClass: UserImplService },
    { provide: MailService, useClass: MailImplService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
