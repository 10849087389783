import { XlsxInfo } from './../entity/xlsxinfo';
import { Observable } from 'rxjs';

export abstract class XlsxService {
  abstract uploadXlsxFile(fd: FormData): Observable<XlsxInfo>
  abstract updateXlsxInfo(fd: FormData): Observable<XlsxInfo>;
  abstract getXlsxFiles(): Observable<XlsxInfo[]>;
  abstract getXlsxFile(id: string): Observable<XlsxInfo>;
  abstract deleteXlsxFile(id: string):  Observable<XlsxInfo>;
  abstract getXlsxSheets(fd: FormData): Observable<String[][]>;
  abstract getXlsxSheetData(fd: FormData): Observable<String[][]>;
  abstract getOutlier(id: string): Observable<any[]>
  abstract viewFileFilterForAdmin(courseId: string, year: number, semester: number): Observable<XlsxInfo[]>;
  abstract viewFileFilterForLecturer(courseId: string, year: number, semester: number, lecturerId: string): Observable<XlsxInfo[]>;
  abstract getXlsxInfoByLecturerEmail(id: string): Observable<XlsxInfo[]>
  abstract viewScoreFilter(courseId: string, year: number, semester: number, studentId: string): Observable<any[]>;
  abstract getStudentScore(studentId: String): Observable<any[]>;
  abstract getHtml(): Observable<String>
  
  
  
}
