import { Injectable } from '@angular/core';
import { XlsxService } from './xlsx.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { XlsxInfo } from '../entity/xlsxinfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class XlsxImplService extends XlsxService {

  constructor(private http: HttpClient) {
    super();
  }

  uploadXlsxFile(fd: FormData): Observable<XlsxInfo> {
    return this.http.post<any>(`${environment.xlsxApi}`, fd);
  }

  updateXlsxInfo(fd: FormData): Observable<XlsxInfo> {
    return this.http.put<any>(`${environment.xlsxApi}`, fd);
  }

  getXlsxFiles(): Observable<XlsxInfo[]> {
    return this.http.get<XlsxInfo[]>(`${environment.xlsxApi}`);
  }

  getXlsxFile(id: string): Observable<XlsxInfo> {
    return this.http.get<XlsxInfo>(`${environment.xlsxApi}/` + id);
  }

  deleteXlsxFile(id: string):  Observable<XlsxInfo>{
    return this.http.delete<XlsxInfo>(`${environment.xlsxApi}/${id}`);
  }

  getXlsxSheets(fd: FormData): Observable<String[][]> {
    return this.http.post<String[][]>(`${environment.xlsxApi}/sheets`, fd);
  }

  getXlsxSheetData(fd: FormData): Observable<String[][]> {
    return this.http.post<String[][]>(`${environment.xlsxApi}/sheet-data`, fd);
  }

  getOutlier(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.xlsxApi}/outlier/${id}`)
  }

  viewFileFilterForAdmin(courseId: string, year: number, semester: number): Observable<XlsxInfo[]> {
    const tempCourseId = courseId ? courseId : '';
    const tempYear = year ? year : '';
    const tempSemester = semester ? semester : '';
    return this.http.get<XlsxInfo[]>(`${environment.xlsxAdminApi}/filter?courseId=${tempCourseId}&year=${tempYear}&semester=${tempSemester}`);
  }

  viewFileFilterForLecturer(courseId: string, year: number, semester: number, email: string): Observable<XlsxInfo[]> {
    const tempCourseId = courseId ? courseId : '';
    const tempYear = year ? year : '';
    const tempSemester = semester ? semester : '';
    return this.http.get<XlsxInfo[]>(`${environment.xlsxLecturerApi}/filter?courseId=${tempCourseId}&year=${tempYear}&semester=${tempSemester}&email=${email}`);
  }

  getXlsxInfoByLecturerEmail(email: string): Observable<XlsxInfo[]> {
    return this.http.get<XlsxInfo[]>(`${environment.xlsxLecturerApi}?email=${email}`);
  }

  viewScoreFilter(courseId: string, year: number, semester: number, studentId: string): Observable<any[]> {
    const tempCourseId = courseId ? courseId : '';
    const tempYear = year ? year : '';
    const tempSemester = semester ? semester : '';
    return this.http.get<any[]>(`${environment.xlsxStudentApi}/filter?courseId=${tempCourseId}&year=${tempYear}&semester=${tempSemester}&studentId=${studentId}`);
  }

  getStudentScore(studentId: String): Observable<any[]> {
    return this.http.get<any[]>(`${environment.xlsxStudentApi}/${studentId}`);
  }
  
  getHtml(): Observable<String> {
    
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<String>(environment.endpoint + '/test', {headers, responseType: 'text' as 'json'})
  }


 

  

  

}
